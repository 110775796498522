<template>
    <panel class="contact">
        <h1 class="text-center title">
            联系我们<br/>
            <small>Contact us</small>
        </h1>
        <b-container>
            <b-row>
                <b-col lg="8">
                    <b-card>
                        <h2>招商合作</h2>
                        <hr/>
                        <b-form>
                            <b-row>
                                <b-col>
                                    <b-form-group>
                                        <b-form-input placeholder="姓名"/>
                                    </b-form-group>
                                    <b-form-group>
                                        <b-form-input placeholder="企业名称"/>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group>
                                        <b-form-input placeholder="电话"/>
                                    </b-form-group>
                                    <b-form-group>
                                        <b-form-input placeholder="QQ/Email"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-form-group>
                                <b-form-textarea placeholder="留言内容" rows="4"/>
                            </b-form-group>
                            <b-form-group class="text-center">
                                <b-btn variant="danger" class="w-25" pill @click="submit">提交</b-btn>
                            </b-form-group>
                        </b-form>
                    </b-card>
                </b-col>
                <b-col lg="4">
                    <b-card>
                        <h2>深圳<br/>
                            <small>SHENZHEN</small>
                        </h2>

                        <h5><b-icon-building/> 地址</h5>
                        <address><p>广东省深圳市龙岗区坂田街道</p></address>
                        <hr/>

                        <h5><b-icon-telephone/> 电话</h5>
                        <a href="tel:18863693890"><p>18863693890</p></a>
                        <hr/>

                        <h5><b-icon-envelope/> 邮箱</h5>
                        <a href="mailto:business@makerfac.com"><p>business@makerfac.com</p></a>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </panel>
</template>

<script>
import Panel from "@/components/Panel";
export default {
    name: "ContactUs",
    components: {Panel},
    methods: {
        submit() {
            setTimeout(() => {
                alert('我们已经收到您的留言，您也可以直接发送邮件给我们。\n感谢您的来信~')
            }, 500);
        }
    }
}
</script>

<style lang="scss" scoped>
.contact {
    .card {
        background: rgba(0,0,0,.2);
        height: 100%;
        small {
            font-size: 50%;
        }

        p {
            font-size: 60%;
            color: white;
            text-indent: 2rem;
        }

    }
}
</style>