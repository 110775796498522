<template>
    <router-link class="btn mt-3 float-right" :class="{'btn-outline-light': light, 'btn-outline-primary': !light}" :to="to">
        查看更多详情<b-icon-box-arrow-up-right class="p-1"/>
    </router-link>
</template>

<script>
export default {
    name: "ViewMore",
    props: {
        to: String,
        light: Boolean
    }
}
</script>

<style scoped>

</style>