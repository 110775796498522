<template>
    <div :class="{light}" class="panel">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "Panel",
    props: {
        light: Boolean
    }
}
</script>

<style lang="scss" scoped>
    @import "~@/styles/variables.scss";
    .light {
        color: $primary;
        background-color: white;
    }

    .panel {
        padding: 150px 0;
        border-bottom: 10px solid $primary;
    }
</style>