<template>
    <div class="about">
        <panel class="profile">
            <h1 class="text-center title">
                美科简介<br/>
                <small>Company profile</small>
            </h1>
            <b-container>
                <b-row>
                    <b-col lg="8">
                        <p>美科教育成立于2016年，总部位于深圳。核心课程研发由海内外博士、硕士专家团队主导，课程体系全面、科学、互动性强，拥有多项发明和实用型专利。</p>
                        <br/>
                        <p>美科教育常年与各类学校保持稳定的合作关系，在各类青少年素质大赛中曾获得100多项大奖。</p>
                    </b-col>
                    <img class="col-lg-4" src="https://static.makerfac.com/w/about.png">
                </b-row>
                <view-more v-if="!!short" to="/about" light/>
            </b-container>
        </panel>
        <panel class="culture" light v-if="!short">
            <h1 class="text-center title">
                企业文化<br/>
                <small>Company culture</small>
            </h1>
            <b-container class="text-secondary">
                <b-row>
                    <div class="col-lg-8">
                        <p><span class="text-warning">企业愿景：</span>服务万家机构与学校，用科技改变教育</p>
                        <br/>
                        <p><span class="text-info">企业使命：</span>让每一个中国孩子，都有获得优质教育资源的机会</p>
                        <br/>
                        <p><span class="text-warning">经营理念：</span>诚信为本，时效第一；打造客户、员工、股东共赢的生态</p>
                        <br/>
                        <p><span class="text-info">教育理念：</span>求知欲创造世界，好奇心驱动学习</p>
                    </div>
                    <img class="col-lg-4" src="https://static.makerfac.com/w/culture.png">
                </b-row>
            </b-container>
        </panel>

        <contact-us v-if="!short"/>
    </div>
</template>

<script>
import Panel from "@/components/Panel";
import ViewMore from "@/components/ViewMore";
import ContactUs from "@/page/about/ContactUs";
export default {
    name: "index",
    components: {ContactUs, ViewMore, Panel},
    props: {
        short: Boolean
    },
    metaInfo: {
        title: '关于我们-【官网】美科教育少儿编程教育-STEAM教育-编程教育-创客教育-编程竞赛-教育服务品牌-美科编程',
        meta: [
            {
                name: 'keywords',
                content: '编程,美科,教育,少儿编程,美科编程,编程竞赛,STEAM,创客教育,机器人教育,教育培训'
            },
            {
                name: 'description',
                content: '【商务咨询:business@makerfac.com】美科教育专注打造一站式教培机构少儿编程教育,STEM教育,创客教育,编程竞赛的行业解决方案,快速复制办学服务能力。'
            }
        ]
    }
}
</script>

<style lang="scss" scoped>
.about {
    .profile {
        .container {
            p {
                line-height: 3rem;
                text-indent: 2rem;
            }
        }
    }
    .culture {
        .container {
            span {
                font-weight: bold;
            }
        }
    }
}
</style>